import { graphql } from "gatsby";
import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import AccountLogin from "../../components/AccountLogin";
import LayoutFloating from "../../components/LayoutFloating";
import { SITE_TITLE, SITE_DESCRIPTION } from "../../lib/constants";

const Login = (props) => {
  const { t } = useTranslation();
  return (
    <Layout
      pageName="account-login"
      showHeader={false}
      siteTitle={t(SITE_TITLE)}
      siteDescription={t(SITE_DESCRIPTION)}
    >
      <LayoutFloating title={t(SITE_TITLE)}>
        <AccountLogin {...props} />
      </LayoutFloating>
    </Layout>
  );
};

export default connect((state) => state)(Login);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
